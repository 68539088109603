import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"


import "./tailwind.css"

const ArticlesPage = () => (
  <Layout>
    <SEO title="Courses" />
    <div className="relative my-12">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-left">
          <div>
          <h2 className="text-3xl leading-9 tracking-tight font-light text-gray-900 sm:text-4xl sm:leading-10">
            Courses
          </h2>
          </div>
          <p className="mt-3 max-w-2xl text-xl leading-7 text-gray-500 sm:mt-4">
            Additionally to this blog, I work on books and courses to help developers understand GraphQL and become experts at it.
          </p>
        </div>
        
        <div className="mt-12 mx-auto">
          <div className="flex flex-col w-full rounded-lg shadow-lg overflow-hidden">
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm leading-5 font-medium text-indigo-600">
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-300 text-black">
                    Book
                  </span>
                </p>
                <a href="https://book.productionreadygraphql.com" className="block">
                  <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                    Production Ready GraphQL
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                  Learn how to design and build predictable, performant, and secure GraphQL APIs at scale.
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center">
                <div>
                  <p className="text-sm leading-5 font-medium text-gray-900">
                    <a href="#" className="hover:underline">
                      Marc-André Giroux
                    </a>
                  </p>
                  <div className="flex text-sm leading-5 text-gray-500">
                    <time dateTime="2020-03-16">
                      Released March 2020
                    </time>
                    <span className="mx-1">
                      &middot;
                    </span>
                    <span>
                      180 Pages
                    </span>
                  </div>
                </div>
              </div>
            </div> 
          </div>

          <div className="mt-12 flex flex-col w-full rounded-lg shadow-lg overflow-hidden">
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm leading-5 font-medium text-indigo-600">
                  <a href="#" className="hover:underline">
                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-300 text-black">
                      Video Course
                    </span>
                  </a>
                </p>
                <a href="#" className="block">
                  <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                    To Be Announced
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                  I'm currently working on a video course, stay tuned!
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center">
                <div>
                  <p className="text-sm leading-5 font-medium text-gray-900">
                    <a href="#" className="hover:underline">
                      Marc-André Giroux
                    </a>
                  </p>
                  <div className="flex text-sm leading-5 text-gray-500">
                    <time dateTime="2020-03-16">
                      Date TBD
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ArticlesPage
